import { toast } from "react-toastify";
import ListService from "../../services/list-service";
import { RowSelected } from "../../shared/types";
import { AvailableProps } from "./index";

type UseAvailableProps = Omit<AvailableProps, "show" | "rowSelected">;

export const useAvailable = ({ handleCancel }: UseAvailableProps) => {
  const handleConfirm = async (rowSelected: RowSelected[]) => {
    if (rowSelected.length > 0) {
      try {
        let result = await ListService.release(rowSelected);
        if (result.data.success)
          toast.success("Pedidos disponibilizado com sucesso!");
        else toast.error("Houve um erro ao disponibilizar os pedidos.");
        handleCancel();
      } catch {
        toast.error("Erro interno, tente novamente ou mais tarde.");
      }
    } else toast.error("Selecione ao menos um registro para essa ação.");
  };
  return { handleConfirm };
};
