import React from "react";
import { Button, Modal } from "react-bootstrap";
import { RowSelected } from "../../shared/types";
import { useAvailable } from "./useAvailable";

export type AvailableProps = {
  show: boolean;
  handleCancel: () => void;
  rowSelected: RowSelected[];
};
export const Available = ({ show, handleCancel, rowSelected }: AvailableProps) => {
  const { handleConfirm } = useAvailable({ handleCancel });

  return (
    <Modal
      backdrop={true}
      show={show}
      onHide={handleCancel}
      onBackdropClick={handleCancel}
    >
      <Modal.Header>
        <h2>Disponibilizar</h2>
      </Modal.Header>
      <Modal.Body>
        <h6>Você tem certeza que deseja realizar essa ação?</h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" size="lg" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          variant="success"
          size="lg"
          onClick={() => handleConfirm(rowSelected)}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
