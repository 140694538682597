import { RowSelected } from "../shared/types";
import BaseService from "./base-service";

const baseService = new BaseService();
var token: any;

class ListService {
  static release = async (data: RowSelected[]) => {
    token = new URLSearchParams(window.location.href).get("token");
    return baseService.put(
      window.drugReplacementService.base +
        window.drugReplacementService.preValidadao + window.drugReplacementService.updateClosing,
      JSON.stringify(data), token
    );
  };
}

export default ListService;
