import React from "react";
import { BaseNavTabs, BaseScreen } from "react-epharma-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Panel } from "../../components/Panel/index";
export const BeforeClosing = () => {
  return (
    <BaseScreen title="Validação Pré Fechamento">
      <BaseNavTabs
        navs={[
          {
            key: "beforeClosing",
            name: "Pré Fechamento",
            content: <Panel />,
          },
        ]}
        defaultActiveKey="beforeClosing"
      />
      <ToastContainer autoClose={2500} position="top-right" />
    </BaseScreen>
  );
};
