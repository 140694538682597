import React, { FunctionComponent } from "react";
import { Route, HashRouter as Router } from "react-router-dom";
import { BeforeClosing } from "./screen/BeforeClosing";

const Routes: FunctionComponent = () => (
  <Router>
    <Route exact path="/before-closing" component={() => <BeforeClosing />} />
  </Router>
);

export default Routes;
