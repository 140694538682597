import React from "react";
import { BaseGrid, BaseScreen, BaseToolbar } from "react-epharma-components";
import { Available } from "../Available";
import Search from "../Search";
import { usePanel } from "./usePanel";
export const Panel = ({ props }: any) => {
  const {
    url,
    crud,
    columns,
    showModal,
    handleCancel,
    handleSelected,
    rowSelected,
  } = usePanel();
  return (
    <BaseScreen title="">
      <BaseGrid
        {...props}
        name="Pré Fechamentos"
        baseEndpoint={url}
        toolbar={
          <BaseToolbar
            crud={crud}
            search={{
              show: true,
              form: <Search />,
            }}
          ></BaseToolbar>
        }
        mode={{
          type: "automatic",
          automatic: {
            endpoint: "/read",
          },
        }}
        {...props}
        columns={columns}
        onSelect={(data) => {
          handleSelected(data);
        }}
      />
      <Available
        show={showModal}
        handleCancel={handleCancel}
        rowSelected={rowSelected}
      />
    </BaseScreen>
  );
};
