import { createRef, useState } from "react";
import { BaseForm, ISelectOptions } from "react-epharma-components";

export const UseSearch = () => {
  const form = createRef<BaseForm>();
  const clientType = createRef<any>();
  const industry = createRef<any>();
  const period = createRef<any>();
  const client = createRef<any>();
  const [urlClient, setUrlClient] = useState<string>();
  const [urlPeriod, setUrlPeriod] = useState<string>();
  const baseIndustry = `${window.productService.base}${window.productService.industry}`;
  const baseDrugReplacement = `${window.drugReplacementService.base}${window.drugReplacementService.preValidadao}`;
  const urlIndustry = `${baseIndustry}${window.productService.industryRead}`;
  const urlPeriodChain = `${baseDrugReplacement}${window.drugReplacementService.periodChain}`;
  const urlPeriodWhoresaler = `${baseDrugReplacement}${window.drugReplacementService.periodWhoresaler}`;
  const urlChain = `${window.chainService.base}${window.chainService.chain}${window.chainService.chainRead}`;
  const urlWholesaler = `${window.chainService.base}${window.chainService.wholesaler}${window.chainService.wholesalerRead}`;
  const inputsRef = [clientType, industry, period, client];

  const optionClientType: ISelectOptions[] = [
    { value: "R", label: "Rede" },
    { value: "D", label: "Distribuidor" },
  ];

  const optionsDefault = [
    {
      value: "0",
      label: "Todos"
    }
  ];

  const handlePeridDisabled = (empty: boolean) => {
    if (empty) period.current?.setDisabled(true);
    else period.current?.setDisabled(false);
  };

  const setChainData = () => {
    setUrlClient(urlChain);
    setUrlPeriod(urlPeriodChain);
    client.current.reset();
    client.current.setDisabled(false);
    period.current.reset();
    period.current.setDisabled(false);
    console.log('url rede: ' + urlPeriodChain);
  };

  const setWholesalerData = () => {
    setUrlClient(urlWholesaler);
    setUrlPeriod(urlPeriodWhoresaler);
    client.current.reset();
    client.current.setDisabled(false);
    period.current.reset();
    period.current.setDisabled(false);
    console.log('url distribuidor: ' + urlWholesaler);
  };

  const handleChangeUrlCliente = (value: string) => {
    if (value === "R") setChainData();
    else if (value === "D") setWholesalerData();
    else client.current.setDisabled(false);
  };

  return {
    form,
    clientType,
    industry,
    period,
    client,
    inputsRef,
    optionClientType,
    urlIndustry,
    urlPeriod,
    urlClient,
    handlePeridDisabled,
    handleChangeUrlCliente,
    optionsDefault
  };
};
